export default [
  {
    path: '/app/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/',
    name: 'public-landing',
    component: () => import('@/views/pages/landing/Landing.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/experts',
    name: 'public-experts',
    component: () => import('@/views/pages/landing/Experts.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/startups',
    name: 'public-startup',
    component: () => import('@/views/pages/landing/Startup.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/premium',
    name: 'public-premium',
    component: () => import('@/views/pages/landing/Premium.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/pricing',
    name: 'public-pricing',
    component: () => import('@/views/pages/landing/Pricing.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/about',
    name: 'public-about',
    component: () => import('@/views/pages/landing/About.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: () => import('@/views/pages/landing/Privacy.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/terms',
    name: 'public-terms',
    component: () => import('@/views/pages/landing/Terms.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/presskit',
    name: 'press-kit',
    component: () => import('@/views/pages/landing/Presskit.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
]
