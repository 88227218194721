import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    payment: {
      icon: 'BriefcaseIcon',
      title: 'Expert',
      subtitle: 'Questions and Answers for Experts',
      qandA: [
        {
          question: "I’m an expert. How exactly does this work for me?",
          ans: "Hustleforge was built to connect willing experts with those who need expertise. It allows experts to generate an income stream while providing ‘seekers’ with valuable guidance and insight in a very streamlined and efficient way. You provide your availability as a seeker (might be nights, lunch hours, weekends, afternoons) and the seeker will book time with you at an agreed upon rate to gain your perspective and insight. Hustleforge handles the scheduling and payment, and you provide the expertise!",
        },
        {
          question: "What exactly is a seeker?",
          ans:"Seekers are those seeking expertise. These typically are hopeful entrepreneurs, part-time side hustlers, those seeking a career change or to better understand a specific field, or even peers looking for insight from peers in their field.",
        },
        {
          question: "Ok, I signed up as an expert. What do I do now?",
          ans:"There are a couple of ways you can engage with people looking for expertise on the app. You can wait for someone interested in your expertise to chat with you, or you can search for people looking for expertise on the app. \n For more reach and impact, link your social profiles and offer to connect with people who may need your expertise.",
        },
        {
          question: "Someone started a chat with me. What should I do?",
          ans: "Start chatting! Remember, your role as an expert is to do everything you can to provide them the information they are looking for. That includes not leading them on or gaslighting them - if you don’t have the expertise they are looking for, be genuine about it. Our most successful experts focus on providing value to everyone they speak with.",
        },
        {
          question: "What items should I include in my profile?",
          ans: "Be as specific as you can, as our search tool will pick up all keywords in your profile and make it easier for people seeking your expertise to find you. It is always a best practice to complete as much of your profile as possible, as it makes you more attractive to those seeking expertise.",
        },
        {
          question: "How do I showcase my work or experience?",
          ans: "We suggest linking relevant work, documents, presentations or other items in the Bio section of your profile, but this isn’t required. Typically, experts and seekers find out whether or not they are a good fit for each other the moment they begin a conversation and get into the details. ",
        },
        {
          question: "How do I get paid?",
          ans:" We highly recommend you connect your account to Stripe which is our offical third party vendor for taking payments. This will allow you to collect money automatically during the booking process and saving you time on no show meetings. See https://stripe.com/pricing for more information. Otherwise, you need to collect the payment by some other means venmo, whatsapp, etc",
        },
        {
          question: "Why would I link my LinkedIn profile to Hustleforge?",
          ans: "Adding your LinkedIn profile will give you more credibility as an expert and will further elevate your experience and visibility among those seeking expertise. Hustleforge will review your accout to make sure your profile and industry match what is listed.",
        },
        {
          question: "What is a LinkedIn-verified account?",
          ans: "Adding your LinkedIn profile will give you more credibility as an expert and will further elevate your experience and visibility among those seeking expertise. ",
        },
      ],
    },
    delivery: {
      icon: 'SearchIcon',
      title: 'Seekers',
      subtitle: 'Questions and Answers for Seekers',
      qandA: [
        {
          question: "Ok, I signed up and indicated I am seeking expertise. What do I do now?",
          ans: "Congratulations, you’re well on your way to finding the expertise and perspective you are looking for! Hustleforge will also send potential matches based on your profile and idea."
        },
        {
          question: "How do I know my contact is an expert",
          ans: "In some cases, verifying expertise is simple (i.e. do you know ArcGIS?). More often, the question will depend. Sometimes, you need someone with specific industry experience over a length of time (i.e. ArcGIS user for 10 years). Sometimes that might not matter to you. This is where you use the chat function to help you get to the root of the question and your expert’s specific expertise in the area.",
        },
        {
          question: "What if my expert turns out not to have the expertise I need?",
          ans: "Experts are incentivized to be as up front with you as possible, and to not mislead you or misrepresent their experience. They want more users to seek their expertise and to find value in their guidance. That said, we do allow full refunds should the need arise (minus a small processing fee). Contact support@hustleforge.com for more information if you require a refund.",
        },
        {
          question: "What is a LinkedIn verified account?",
          ans: "When an expert adds their LinkedIn account, this Hustleforge validates the expert’s profile make sure they are more credible.",
        },
        {
          question: "Can I see my expert’s LinkedIn account?",
          ans: "The expert can provide their information via the chat function if they so choose, however they are not required to (for privacy purposes)."
        },
      ],
    },
    cancellationReturn: {
      icon: 'CreditCardIcon',
      title: 'Payments and Costs',
      subtitle: 'Payment and Costs Questions and Answers',
      qandA: [
        {
          question: 'Is Hustleforge free?',
          ans: "Yes, at this time we do not charge a platform fee, but that may change in the future. We use stripe as our payment processor and recommend experts to signup to simplify bookings and accept payments. Stripe has a processing fee of 2.9% of the total transaction + $0.30 per transaction.",
        },
        {
          question: "Can I change what I charge depending on what the seeker needs or what I agree upon?",
          ans:"Not at this time, but in future updates, you will be able to change what you charge at your discretion.",
        },
        {
          question: 'Can I provide my expertise for free?',
          ans: "At this point, you cannot provide your expertise for free during a scheduled appointment with a mentor. You can, however, provide your expertise via chat for free. You will be able to change what you charge in future updates, which will include the ability to not charge anything.",
        },
        {
          question: 'What if I need to refund the transaction?',
          ans:"You can refund the transaction minus the stripe processing fee. Contact us via email support@hustleforge.com and we can assist in making this happen for you.",
        },
        {
          question: "Can I negotiate the rate with the expert?",
          ans: "Not at this time, but this feature will be added in future releases",
        },
      ],
    },
    myOrders: {
      icon: 'StarIcon',
      title: 'Ratings and Reviews',
      subtitle: 'Ratings and Reviews Questions and Answers',
      qandA: [
        {
          question: 'What do ratings mean? How do I rate someone?',
          ans: "Ratings are the core ‘currency’ for experts in Hustleforge. Experts want decent ratings to ensure new prospects that they provide value, are helpful and knowledgeable, and are genuine. Make sure to leave a review, it really matters!",
        },
        {
          question: 'Why ratings?',
          ans: "Ratings are the best way for you to attract individuals seeking expertise who may not know you or your skillset and how helpful/informative you are, and ratings are a great way to show the community how well you can inform and guide those seeking expertise like yours. On hustleforge, positive ratings will help you be successful. ",
        },
        {
          question: "When do ratings happen?",
          ans:"The seeker and the expert will receive a rating requests after the meeting has concluded.",
        },
        {
          question: 'Uh oh! I have a bad rating! Is my time in Hustleforge over?',
          ans: "Absolutely not. Ratings will normalize over time. In some cases, the Hustleforge support team will reach out and investigate ratings on your behalf to see if we can mitigate the problem, thus giving you the opportunity for the rating to be expunged.",
        },
        {
          question: 'How do I dispute a rating if I believe it is unfair?',
          ans: "Contact support@hustleforge.com and the Hustleforge team will review the complaint.",
        },
        {
          question: 'What is the ‘report’ function for?',
          ans: "While ratings are set up to help people guide which expert they want to engage with, the ‘report’ function is available if an expert is not who they say they are or actively tries to mislead the seeker or misrepresent their own expertise.",
        },
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
