import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import { timestamp } from 'rxjs'

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      fullName: 'Robert Smith',
      username: 'robert-smith',
      company: 'Yotz PVT LTD',
      role: 'editor',
      type: 'Expert',
      country: 'USA',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      status: 'inactive',
      image: require('@/assets/images/avatars/2.png'),
      rate: 100.00,
      rating: 4,
      reviewCount: 100,
      responses: 1000,
      clients: 21,
      industry: 'Computer Software',
      language: 'English',
      tagline:'I’m a software engineer in Santa Barbara, CA with a passion for computer science, electrical engineering and embedded systems technology.', // Word count 155
      bio: '',
      reviews:[{
        id: 1,
        userid: 4,
        fullName: 'Derrick Absher',
        username: 'derrick-absher',
        image: require('@/assets/images/avatars/4.png'),
        rating: 3.5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"January 28, 2022"
      },
      {
        id:2,
        userid: 5,
        fullName: 'Aileen Chandler',
        username: 'aileen-chandler',
        image: require('@/assets/images/avatars/5.png'),
        rating: 5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"Feburary 26, 2022"
      }]
    },
    {
      id: 2,
      fullName: 'Kathy Lewis',
      username: 'kathy-lewis',
      company: 'Skinder PVT LTD',
      role: 'author',
      type: 'Seeker',
      country: 'USA',
      contact: '(472) 607-9137',
      email: 'hredmore1@imgur.com',
      currentPlan: 'team',
      status: 'pending',
      image: require('@/assets/images/avatars/1.png'),
      rate: 20.00,
      rating: 4,
      reviewCount: 9,
      responses: 10,
      clients: 0,
      industry: 'Accounting',
      language: 'English',
      tagline:'Wannabe web fanatic. Lifelong communicator. Student. Entrepreneur. Certified thinker. Organizer.',
      bio: '',
      idea:'This is my problem',
      reviews:[{
        id: 1,
        userid: 4,
        fullName: 'Derrick Absher',
        username: 'derrick-absher',
        image: require('@/assets/images/avatars/4.png'),
        rating: 3.5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"January 28, 2022"
      },
      {
        id:2,
        userid: 5,
        fullName: 'Aileen Chandler',
        username: 'aileen-chandler',
        image: require('@/assets/images/avatars/5.png'),
        rating: 5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"Feburary 26, 2022"
      }]
    },
    {
      id: 3,
      fullName: 'Faye Dumas',
      username: 'Faye Dumas',
      company: 'Oozz PVT LTD',
      role: 'maintainer',
      country: 'Russia',
      contact: '(321) 264-4599',
      email: 'msicely2@who.int',
      currentPlan: 'enterprise',
      status: 'active',
      image: require('@/assets/images/avatars/3.png'),
      tagline:'Subtly charming tv scholar. Music trailblazer. Unapologetic creator. Incurable alcohol buff.',
      bio: '',
      industry: 'Healthcare',
      language: 'English',
      type: 'Expert',
      rate: 30.00,
      rating: 5,
      reviewCount: 15,
      responses: 35,
      clients: 6,
      reviews:[{
        id: 1,
        userid: 4,
        fullName: 'Derrick Absher',
        username: 'derrick-absher',
        image: require('@/assets/images/avatars/4.png'),
        rating: 3.5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"January 28, 2022"
      },
      {
        id:2,
        userid: 5,
        fullName: 'Aileen Chandler',
        username: 'aileen-chandler',
        image: require('@/assets/images/avatars/5.png'),
        rating: 5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"Feburary 26, 2022"
      }]
    },
    {
      id: 4,
      fullName: 'Derrick Absher',
      username: 'derrick-absher',
      company: 'Oozz PVT LTD',
      role: 'maintainer',
      country: 'USA',
      contact: '(923) 690-6806',
      email: 'crisby3@wordpress.com',
      currentPlan: 'team',
      status: 'inactive',
      image: require('@/assets/images/avatars/4.png'),
      tagline: 'Social media fanatic. Infuriatingly humble coffee scholar. Music buff. Freelance beer expert.',
      bio: '',
      industry: 'Food Preparation',
      language: 'English',
      type: 'Seeker',
      idea:'This is my problem',
      rate: 50.00,
      rating: 0,
      reviewCount: 0,
      responses: 0,
      clients: 0,
      reviews:[],
    },
    {
      id: 5,
      fullName: 'Aileen Chandler',
      username: 'aileen-chandler',
      company: 'Aimbo PVT LTD',
      role: 'subscriber',
      country: 'Mexico',
      contact: '(669) 914-1078',
      email: 'mhurran4@yahoo.co.jp',
      currentPlan: 'enterprise',
      status: 'pending',
      image: require('@/assets/images/avatars/5.png'),
      tagline: 'Total troublemaker. Alcohol fan. Social media specialist. Friendly travel nerd.',
      bio: '',
      industry: 'Architecture',
      language: 'English',
      type: 'Both',
      rate: 70.00,
      rating: 2,
      reviewCount: 2,
      responses: 8,
      clients: 1,
      reviews:[],
    },
    {
      id: 6,
      fullName: 'Gary Abraham',
      username: 'gary-abraham',
      company: 'Jaxbean PVT LTD',
      role: 'author',
      country: 'China',
      contact: '(958) 973-3093',
      email: 'shalstead5@shinystat.com',
      currentPlan: 'company',
      status: 'active',
      image: require('@/assets/images/avatars/6.png'),
      tagline:'Problem solver. Wannabe food ninja. Incurable zombie maven. Subtly charming thinker. Professional web geek. Friendly introvert.',
      bio: '',
      industry: 'Education',
      type: 'Expert',
      language: 'English',
      rate: 35.00,
      rating: 4,
      reviewCount: 2,
      responses: 25,
      clients: 6,
      reviews:[{
        id: 1,
        userid: 4,
        fullName: 'Derrick Absher',
        username: 'derrick-absher',
        image: require('@/assets/images/avatars/4.png'),
        rating: 3.5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"January 28, 2022"
      },
      {
        id:2,
        userid: 5,
        fullName: 'Aileen Chandler',
        username: 'aileen-chandler',
        image: require('@/assets/images/avatars/5.png'),
        rating: 5,
        comment: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        time:"Feburary 26, 2022"
      }]
    },
  ],
  userWishlist: [
    { id: 1, userId: 1 },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/users/search').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', sortBy = 'featured', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.users.filter(user => (
    user.fullName.toLowerCase().includes(queryLowered) 
    || user.industry.toLowerCase().includes(queryLowered)
    || user.description.toLowerCase().includes(queryLowered)))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'rate'
    }
    if (sortBy === 'price-asc') {
      return 'rate'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(user => {
    /* eslint-disable no-param-reassign */
    user.isInWishlist = data.userWishlist.findIndex(p => p.userId === user.id) > -1
    /* eslint-enable */
  })

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart,
    },
  ]
})


// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/user/users').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (plan || user.currentPlan) &&
      user.status === (status || user.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User by id
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})

// ------------------------------------------------
// GET: Return Single User by username
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\.*/).reply(config => {
  // Get username from URL
  let username = config.url.substring(config.url.lastIndexOf('/') + 1)

  const userIndex = data.users.findIndex(e => e.username === username)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})