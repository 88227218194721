import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  pricing: {
    basicPlan: {
      title: 'Starter',
      img: require('@/assets/images/illustration/demand.svg'),
      subtitle: 'Great for people looking to start',
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnual: 0,
      },
      planBenefits: [
        'Public Profile',
        'Browse User Profiles',
        'Unlimited Meetings',
        'Unlimited Messages',
        'Unlimited Connections',
        'Automatic Scheduled Meetings with Calendar Integration (Google Meet)',
        'Payments Collected at Booking Automatically with Payment Integration (Stripe)',
      ],
      popular: false,
    },
    standardPlan: {
      title: 'Professional',
      img: require('@/assets/images/illustration/email.svg'),
      subtitle: 'For professionals looking to take full advantage of Hustleforge',
      monthlyPrice: 10,
      yearlyPlan: {
        perMonth: 9,
        totalAnual: 108,
      },
      planBenefits: [
        'Public Profile',
        'Browse User Profiles',
        'Unlimited Meetings',
        'Unlimited Messages',
        'Unlimited Connections',
        'Rescheduled Bookings',
        'Managed Refunds and Cancelations',
        'Automatic Scheduled Meetings with Calendar Integration (Google Meet)',
        'Payments Collected at Booking Automatically with Payment Integration (Stripe)',
        'Managed Refunds and Cancellations',
        'Profile Verification with LinkedIn',
        'NDAs and Contracts',
        'Video Recordings',
        'Targeted Surveys',
        'Background checks',

      ],
      popular: true,
      stripeProductID: 'price_1N3uEiKqdq1kAUs2P4LbIYND',//'price_1N3smZKqdq1kAUs2EO1A2dUA',
    },
    enterprisePlan: {
      title: 'Enterprise',
      img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'Solution for big organizations',
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: 80,
        totalAnual: 960,
      },
      planBenefits: [
        'PayPal payments',
        'Logic Jumps',
        'File upload with 5GB storage',
        'Custom domain support',
        'Stripe integration',
      ],
      popular: false,
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans:
          'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans:
          'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/pricing/data').reply(() => [200, data.pricing])
