export default [
  {
    path: '/app/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },
  {
    path: '/app/profile',
    name: 'apps-profile',
    component: () => import('@/views/apps/user/users-view/ProfileView.vue'),
  },
  {
    path: '/app/create-profile',
    name: 'apps-create-profile',
    component: () => import('@/views/apps/user/users-view/UserCreateProfile.vue'),
    meta: {
      pageTitle: 'Create Profile',
    },
  },
  {
    path: '/app/edit-profile',
    name: 'apps-edit-profile',
    component: () => import('@/views/apps/user/users-view/UserCreateProfile.vue'),
    meta: {
      pageTitle: 'Edit Profile',
    },
  },
  {
    path: '/app/availability',
    name: 'apps-edit-availability',
    component: () => import('@/views/apps/user/users-view/UserAvailabilityView.vue'),
    meta: {
      pageTitle: 'Availability',
    },
  },
  {
    path: '/app/integrations',
    name: 'integrations',
    component: () => import('@/views/apps/integrations/Integrations.vue'),
    meta: {
      pageTitle: 'Integrations',
    },
  },
  {
    path: '/app/share',
    name: 'apps-share',
    component: () => import('@/views/apps/share/shareProfile.vue'),
  },
  {
    path: '/app/feature',
    name: 'apps-feature',
    component: () => import('@/views/apps/feedback/requestFeature.vue'),
  },
  {
    path: '/app/bug',
    name: 'apps-bug',
    component: () => import('@/views/apps/feedback/submitBug.vue'),
  },
  {
    path: '/app/review/:id',
    name: 'apps-review',
    component: () => import('@/views/apps/feedback/review.vue'),
  },
  {
    path: '/app/integrations/stripe',
    name: 'integrations-stripe',
    component: () => import('@/views/apps/integrations/IntegrationStripe.vue'),
    meta: {
      pageTitle: 'Integrations',
      breadcrumb: [
        {
          text: 'Stripe',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/integrations/google',
    name: 'integrations-google',
    component: () => import('@/views/apps/integrations/IntegrationGoogle.vue'),
    meta: {
      pageTitle: 'Integrations',
      breadcrumb: [
        {
          text: 'Google Calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/integrations/linkedin',
    name: 'integrations-linkedin',
    component: () => import('@/views/apps/integrations/IntegrationLinkedin.vue'),
    meta: {
      pageTitle: 'Integrations',
      breadcrumb: [
        {
          text: 'Linkedin',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/app/onboarding',
    name: 'apps-onboarding',
    component: () => import('@/views/apps/onboarding/Onboarding.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/app/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/app/chat/:id',
    name: 'apps-chat-user',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- Search  --------------------------------*
  // *===============================================---*
  {
    path: '/app/search',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/app/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/app/profile/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/app/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
