// Firebase
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getMessaging } from "firebase/messaging/sw"

const firebaseConfig = {
    apiKey: "AIzaSyBF68i-a5ZkhW2gUqO7tJMpHAZpjXnjpeo",
    authDomain: "ideabox-335103.firebaseapp.com",
    projectId: "ideabox-335103",
    storageBucket: "ideabox-335103.appspot.com",
    messagingSenderId: "1082487788583",
    appId: "1:1082487788583:web:bf178a20c88d9364272a02",
    measurementId: "G-9E1F3LYG8N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth()
const messaging = getMessaging(app)

export { app, analytics, auth, onAuthStateChanged, messaging }