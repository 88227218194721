<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'
import { getMessaging, onMessage, getToken, isSupported } from "firebase/messaging"
import { getAuth } from "firebase/auth"
import { getFirestore, updateDoc, doc, arrayUnion} from 'firebase/firestore'
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout () {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType () {
      return this.$store.state.appConfig.layout.type
    }
  },
  methods: {
    notifcationSupported(){
      return 'Notification' in window &&
      'serviceWorker' in navigator && 
      'PushManager' in window
    },
  },
  async mounted(){
    const auth = getAuth()
    const db = getFirestore() 
    const user = (auth && auth.currentUser)?auth.currentUser: null
    if(!user) return // No need to setup notifications
    
    const userData = JSON.parse(localStorage.getItem('userData'))
    if(!userData) return // No need to setup notifications

    if(!isSupported() || !this.notifcationSupported()) return
    
    const messaging = getMessaging()
    const permission = await Notification.requestPermission()
    if (permission === 'granted'){
      getToken(messaging, { vapidKey: 'BJluftTUTm76uZqpL8BhlG0I_d25Hos_qxZhciubU-3MAWbcjrbnd-pRgf3WFB_NQIO7B5YLbgmE1SxfNUYHeck' }).then((currentToken) => {
        console.log(currentToken)
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          if(userData.pushToken !== currentToken){
            userData.pushToken = currentToken
            // Update the profile  
            updateDoc(doc(db, "profiles", user.displayName ), { pushTokens: arrayUnion(currentToken)})
            
            // Update the local storage
            localStorage.setItem('userData', JSON.stringify(userData))
          }
        } 
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })
    }

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Play notifcation
      const notifcaionSound = require("@/assets/sounds/ring.mp3")
      const audio = new Audio(notifcaionSound)
      audio.play()

      const title = (payload && payload.notification)?payload.notification.title: "New Message"
      const message = (payload && payload.notification)?payload.notification.body: ""

      this.$toast({
        component: ToastificationContent,
        // TODO add a click handler
        props: {
          title: title,
          icon: 'ChatIcon',
          variant: 'primary',
          text: message,
        },timeout: 10000
      })
    });
  },
  beforeCreate () {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup () {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 4000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
