import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueFilterDateFormat from 'vue-filter-date-format';
import { auth, onAuthStateChanged, messaging } from "./firebase";
import VueMeta from 'vue-meta'
import Clipboard from 'v-clipboard'
import Rollbar from 'rollbar'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// Hotjar
import Hotjar from 'vue-hotjar'

// VueParticles
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Date format
Vue.use(VueFilterDateFormat)

// Use VueMeta
Vue.use(VueMeta, {
  // optional pluginOptions
  // refreshOnceOnNavigation: true
})

// Clipboard
Vue.use(Clipboard)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$stripeKey = 'pk_test_51KmMUZKqdq1kAUs2gSJba44PEVQdgHZahaSyNoYHFCgN3Tkih1omUlVVkGlPCZEW1bwsYAP5BtDicPtcilXX48Gx00LTjHKzok'

if(process.env.NODE_ENV === 'production'){

  // Set Stripe Key
  Vue.prototype.$stripeKey = 'pk_live_51KmMUZKqdq1kAUs2CPGPNhSbvxKQnBiPV2hdkvIJzzFbKtFsYjTJcMcLKYzxDVlTe15P4cjnbPnIIaFQALKnABMU00lOjYtaMQ'

  // Set the Rollbar instance in the Vue prototype
  // before creating the first Vue instance.
  // This ensures it is available in the same way for every
  // instance in your app.
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: '1fefe9263208465da715349047425c4d',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      // Track your events to a specific version of code for better visibility into version health
      code_version: '0.1.0',
      environment: process.env.NODE_ENV
    }
  })

  // If you have already set up a global error handler,
  // just add `vm.$rollbar.error(err)` to the top of it.
  // If not, this simple example will preserve the app’s existing
  // behavior while also reporting uncaught errors to Rollbar.
  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  }
}

Vue.use (Hotjar, {
  id: '3480355',
  isProduction: (process.env.NODE_ENV === 'production')?true:false
})


// Set Firebase Globals
Vue.prototype.$messaging = messaging

let vueApp = '';
onAuthStateChanged(auth, (user) => {
  if(!vueApp){
    vueApp = new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }else if(user){  
    // Linkedin hack to re-route after login
    setTimeout(() => { if(router.history.current.path === '/login') router.go() }, 1000) 
  }
});
